import Layout from "../components/Layout";
import { useSelector} from "react-redux";

const UserProfile = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <Layout>
         
      <h1 className="text-center">Your Profile</h1>
      {user && (<div >

        <div
        className="card m-2"
        style={{ cursor: "pointer" }}
      >

       <div className="card-header">
       <b>Name: </b> {user.name} 
        </div>
        <div className="card-body">
          <p>
            <b>Email Address: </b>  {user.email}
          </p>
          <p>
            <b>Have you ever applied for Doctor's Post ?: </b> {user.hasApplied===true?"YES":"NO"}
          </p>
          <p>
            <b>Are you a doctor ?: </b> {user.isDoctor===true?"YES":"NO"}
          </p>
         
        </div>
        </div>
        </div>
      )}
    </Layout>
  );
};

export default UserProfile;
