import React from "react";
import "../styles/LayoutStyle.css";
import { adminMenu, userMenu } from "./../data/data";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, message } from "antd";


const Layout = ({ children }) => {
  const {user}  = useSelector((state) => state.user);
  // console.log(user.name);
  // console.log("user");
  const location = useLocation();
  const navigate = useNavigate();
  // logout funtion
  const handleLogout = () => {
    localStorage.clear();
    message.success(user.name + " Logged out Successfully");
    navigate("/login");
  };

  // =========== doctor menu ===============
  const doctorMenu = [
    {
      name: "Home",
      path: "/",
      icon: "fa-solid fa-house",
    },
    {
      name: "Appointments",
      path: "/doctor-appointments",
      icon: "fa-solid fa-list",
    },

    {
      name: "Manage Profile",
      path: `/doctor/profile/${user?._id}`,
      icon: "fa-solid fa-user",
    },
  ];
  // =========== doctor menu ===============

  // redering menu list
  const SidebarMenu = user?.isAdmin? adminMenu:
  user?.isDoctor?doctorMenu: userMenu;
  return (
    <>
      <div className="main">
        <div className="layout">
          <div className="sidebar">
            <div className="logo">
              <h6 className="text-light">
                HealthSync
              </h6>
              <hr />
            </div>
            <div className="menu">
              {SidebarMenu.map((menu) => {
                const isActive = location.pathname === menu.path; // this is done to add white color in that menu which is clicked
                return (
                  <>
                 
                    <div className={`menu-item ${isActive && "active"}`}>
                      <i className={menu.icon}></i>
                      <Link to={menu.path}>{menu.name}</Link>
                    </div>
                  </>
                );
              })}
              <div className={`menu-item `} onClick={handleLogout}>
                <i className="fa-solid fa-right-from-bracket"></i>
                <Link to="/login">Logout</Link>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="header">
              {/* <h6  className="text-center">Click on doctor's profile to book an appointment</h6> */}
              <span className="header-content" style={{ cursor: "pointer" }}>
             
                <Badge
                  count={user?.notification.length}
                  onClick={() => {
                    navigate("/get-all-notification");
                  }}
                >
                  <i className="fa-solid fa-bell"></i>
                </Badge>

                <Link 
                to = "/profile"
                className="fa-solid logo" onClick={() => {
                  navigate("/profile");
                }}
                >{user?.name}</Link>
              </span>
              {/* <span className="text-center">Click on doctor's profile to book an appointment</span> */}
            </div>
            <div className="body">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};


export default Layout;